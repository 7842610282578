@import url('https://fonts.googleapis.com/css?family=Lato:500,400,300,200,100|Muli:400,600,700');
@import './loader.scss';
@import '~react-quill/dist/quill.snow.css';

.ql-editor {
    min-height: 100px;
}
.ql-editor p {
    margin-bottom: 10px;
}

$body-bg: #E8E8E8;
$primary: #053C79;

.card {
    padding: 10px;
    background-color: #FFF;
    border: 1px solid #CCC;
    margin-bottom: 10px;
    border-radius: 16px;
}


.manage-campaign-title {
    background-color: rgba(0,0,0,.4);
    color: #FFF;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px;
}
.manage-large-container {
    max-width: 900px !important;
    padding-top: 20px;
}

.manage-share-box {
    height: 0;
    text-align: center;
    transition: all .5s ease-in-out;
    overflow: hidden;
}
.manage-share-box.showing {
    height: 90px;
    margin: 10px auto;
}


.fun-label-wrapper {
    position: relative;   
    margin-bottom: 15px; 
}
.fun-label-wrapper .fun-label-label {
    color: #999;
    left: .625rem;
    padding-left: .5rem;
    padding-right: .5rem;
    pointer-events: none;
    position: absolute;
    top: .5625rem;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}
.fun-label-input:focus+.fun-label-label, .fun-label-wrapper.has-value .fun-label-label {
    background: #fff;
    color: #333;
    font-size: .875rem;
    line-height: .875rem;
    top: -.4em;
} 

.login-form-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 60vh;
}

.login-panel {
    align-self: center;
    width: 100%;
    max-width: 350px;
}

.invite-campaign-title {
    font-size: 18px;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.team-member-card {
    position: relative;
    margin-top: 10px;
    background-color: #FFF;
    height: 60px;
    width: 100%;
    border: 1px solid #EFEFEF;
    border-radius: 5px;
}
.team-member-avatar {
    position: absolute;
    left: 5px;
    top: 5px;
}
.team-member-avatar img {
    height: 50px;
    border-radius: 30px;
}
.team-member-name {
    position: absolute;
    left: 60px;
    top: 5px;
    font-size: 18px;
}
.team-member-description {
    position: absolute;
    left: 60px;
    top: 30px;
    color: #999;
    font-size: 14px;
}
.team-member-actions {
    position: absolute;
    top: 5px;
    right: 10px;
}
.team-member-raised {
    position: absolute;
    top: 28px;
    right: 10px;
}

.slide-in-page-container {
    position: absolute;
    width: 0;
    transition: all .5s ease-in-out;
    background-color: #FFF;
    overflow: hidden;
    border: 1px solid #EFEFEF;
}
.slide-in-page-container.visible {
    padding: 10px;
    width: auto;
    max-width: 100%;
}
.slide-in-page-container.top-right{
    top: 0;
    right: 0;
    .slide-in-close-button {
        float: left;
        margin-right: 10px;
    }
}
.slide-in-page-container.top-left{
    top: 0;
    left: 0;
    .slide-in-close-button {
        float: right;
        margin-right: 10px;
    }
}
.slide-in-page-container.bottom-right{
    bottom: 0;
    right: 0;
    .slide-in-close-button {
        float: left;
        margin-right: 10px;
    }
}
.slide-in-page-container.bottom-left{
    bottom: 0;
    left: 0;
    .slide-in-close-button {
        float: right;
        margin-right: 10px;
    }
}

.small-create-nav {
    margin: 15px auto;
}

.pulse-button {

    box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.7);
    cursor: pointer;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }
  .pulse-button:hover 
  {
    -webkit-animation: none;-moz-animation: none;-ms-animation: none;animation: none;
  }
  
  @-webkit-keyframes pulse {to {box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);}}
  @-moz-keyframes pulse {to {box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);}}
  @-ms-keyframes pulse {to {box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);}}
  @keyframes pulse {to {box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);}}

@import '~bootstrap/scss/bootstrap.scss';

//AFTER BOOTSTRAP!
@import './social_buttons.scss';
@import './image_cropper.scss';

body {
    font-family: 'Muli', 'Lato';
}

.card {
    border-radius: 16px;
}

.container {
    position: relative;
}

.nav-tabs .nav-link {
    border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link:focus {
    outline: none
}

.dropdown-toggle::after {
    vertical-align: .15em;
}

.btn {
    border-radius: 16px;
}
.btn-order-sort {
    width: 100%;
    border-radius: 0;
    border: none;
    height: 100%;
    font-size: 34px;
    line-height: 1;
}
.btn-order-sort:focus {
    outline: none;
    box-shadow: none;
  }

.btn-fun-transparent-full {
    border: 0;
    font-size: 18px;
    text-align: left;
    width: 100%;
    border-radius: 16px;
    color: #000;
}
.btn-fun-transparent-full:hover {
    background-color: #f1f2f6;
}
.btn-fun-transparent-full > i {
    background-color: #f1f2f6;
    padding: 10px;
    border-radius: 40px;
    margin-right: 10px;
    color: #000;
}

.modal-content {
    border-radius: 16px;
}

#bottomBanner {
    text-align: center;
    margin: auto;
    padding: 30px;
    font-size: 18px;
    background-color: #FFF;
    margin-bottom:-15px;
}

#footer {
    width: 100%;
    margin-top: 15px;
    color: #FFF;
    padding: 20px 10px 10px;
    background-color: #9bb1c9;
    border-top: 5px solid #053C79;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
}
#footer h4 {
    font-size: 18px;
}
#footer h3 {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 300;
    line-height: 1.2;
}
#footer a:link, #footer a:visited {
    margin-left: 10px;
    color: #EFEFEF;
    text-decoration: none;
}
#footer a:hover {
    color: #FFF;
    text-decoration: underline;
}


//MODIFICATIONS TO BOOTSTRAP CLASSES GO BELOW HERE

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFF;
    border-color: #dee2e6 #dee2e6 #FFF;
}

img {
    max-width: 100%;
}

.manage-small-nav-link {
    background-color: #FFF;
    padding: 1rem;
    color: #777;
    border: none;
    border-bottom: 1px solid #EFEFEF;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    text-align: left;
}
.manage-small-nav-link:focus {
    outline: none;
}

nav {
    font-family: 'Raleway', 'Lato', 'Helvetica', sans-serif;
    box-shadow: 0px 1px 10px 0 #999;
}
.navbar-brand {
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
}
b, strong {
    font-weight: bold;
}

.navbar-toggler-icon {
    height: 1em;
    width: 1em;
}

.progress {
    margin-bottom: 0;
}

#autocomplete-mask {
    display: none;
    background: rgba(0,0,0,0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}
.autocomplete-options-holder {
    position: absolute;
    top: 0px;
    left: 2px;
    margin-top: 38px;
    z-index: 2;
    background-color: #FFF;
}
.ac-option, .my-campaigns-option {
    padding: 10px;
    border-bottom: 1px solid #CCC;
}
.ac-option:hover, .my-campaigns-option:hover {
    cursor: pointer;
    background-color: #EFEFEF;
}

.ac-option {
    width: 330px;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .8);
    font-weight: 400;
}

.form-control.big-input {
    font-size: 1.5rem;
}

.navbar .nav-link {
    padding: 0 1rem;
}

.collapsed-menu-link {
    padding: 20px 10px !important;
    border-bottom: 1px solid #EFEFEF;
}

.confirmation-option {
    margin: 10px auto;
    padding: 10px;
    border: 1px solid #EFEFEF;
    cursor: pointer;
    background: none;
    text-align: left;
    display: block;
    width: 100%;
    color: #000;
}
.confirmation-option:hover {
    background-color: #EFEFEF;
    text-decoration: none;
}
.confirmation-option.selected {
    border: 1px solid #a0d79d;
    background-color: #d8f3db;
    color: #000;
}
.vault-option {
    border: 1px solid #CCC;
    padding: 5px;
    display: block;
    margin: 0 10px 10px 0;
    cursor: pointer;
    position: relative;
    box-shadow: rgb(18 38 63 / 3%) 0px 0.75rem 1.5rem;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(0px);
}
.vault-option:hover {
    //box-shadow: 0, 0, 0, .8;
    box-shadow: rgb(45 45 45 / 5%) 0px 2px 2px, rgb(49 49 49 / 5%) 0px 4px 4px, rgb(42 42 42 / 5%) 0px 8px 8px, rgb(32 32 32 / 5%) 0px 16px 16px, rgb(49 49 49 / 5%) 0px 32px 32px, rgb(35 35 35 / 5%) 0px 64px 64px;
    transform: translate(0px, -4px);
}
.vault-option>i {
    float: left;
    font-size: 40px;
}
.vault-option-files, .vault-option-mb, .vault-option-title {
    margin-left: 50px;
}
.vault-delete-holder {
    position: absolute;
    top: 12px;
    right: 5px;
}
.file-list-item {
    //box-shadow: rgb(18 38 63 / 3%) 0px 0.75rem 1.5rem;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(0px);
    cursor:pointer;
}
.file-list-item:hover {
    box-shadow: rgb(45 45 45 / 5%) 0px 2px 2px, rgb(49 49 49 / 5%) 0px 4px 4px, rgb(42 42 42 / 5%) 0px 8px 8px, rgb(32 32 32 / 5%) 0px 16px 16px, rgb(49 49 49 / 5%) 0px 32px 32px, rgb(35 35 35 / 5%) 0px 64px 64px;
    transform: translate(0px, -4px);
}

.question-menu-item {
    display: block;
    padding: 15px 5px;
    border: none;
    background-color: #FFF;
    text-align: left;
    border-bottom: 1px solid #CCC; 
    text-decoration: none;
    color: #000;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(0px);
    width: 100%;
}
.question-menu-item.active {
    background-color: #f6f6f6;
}
.question-menu-item:hover {
    box-shadow: rgb(45 45 45 / 5%) 0px 2px 2px, rgb(49 49 49 / 5%) 0px 4px 4px, rgb(42 42 42 / 5%) 0px 8px 8px, rgb(32 32 32 / 5%) 0px 16px 16px, rgb(49 49 49 / 5%) 0px 32px 32px, rgb(35 35 35 / 5%) 0px 64px 64px;
    transform: translate(0px, -4px);
}

.free-task-option {
    border: 1px solid #CCC;
    border-radius: 8px;
    padding: 8px;
    margin-top: 10px;
}
.free-task-mb {
    float: left;
    font-size: 20px;
    border: 1px solid #CCC;
    border-radius: 8px 0 8px 0;
    margin: -8px 10px 10px -8px;
    padding: 5px;
    background-color: #31a631;
    color: #FFF;
}
.free-task-title {
    font-weight: bold;
    font-size: 20px;
}


@media (max-width: 450px){
    .container {
        padding-right: 5px;
        padding-left: 5px;
    }
    .card-body {
        padding: 5px;
    }
    .row {
        margin-left: -5px;
        margin-right: -5px;
    }
}

@media (max-width: 766px){
    .large-screen-only {
        display: none !important;
    }
    .vault-option {
        display: block;
    }
}

@media (min-width: 767px){
    .screen-is-large {
        display: block !important;
    }
    .navbar {
        padding: 0 1rem !important;
    }
    .navbar-nav {
        align-items: center;
    }
    .navbar .nav-link {
        padding: .5rem 1rem;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    
}

