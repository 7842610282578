
  
  .image-cropper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .image-cropper-container {
    width: 100%;
    height: 300px;
    position: relative;
  }
  
  .image-cropper-controls {
    margin: auto;
    width: 90%;
    height: 80px;
    text-align: center;
    padding-top: 10px;
  }

  